import { createSlice } from '@reduxjs/toolkit';

function getClientDate() {
    let client = localStorage.getItem("client");

    if (client === "Setu") {
        return new Date(new Date("2025-01-06").getTime() - 330 * 60000);
    } else if (client === "Brillare") {
        return new Date(new Date("2025-03-11").getTime() - 330 * 60000);
    } else {
        return false;
    }
}

const initialState = {
    startDate: getClientDate() || new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()),
    endDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    storeNumbers: 'all'
};

const dateRangeSlice = createSlice({
    name: 'dateRange',
    initialState,
    reducers: {
        setDateRangeInner: (state, action) => {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.storeNumbers = action.payload.storeNumbers;
        }
    }
});

export const { setDateRangeInner } = dateRangeSlice.actions;
export default dateRangeSlice.reducer;